import { useNavigate } from 'react-router-dom';

import { Heading } from '@chakra-ui/react';

import { useDirectoryMe } from 'src/shared/hooks/useDirectory';

export function ExpertAccepted() {
  const me = useDirectoryMe();
  const navigate = useNavigate();
  if (!me.GS1PK) {
    navigate('/');
    return null;
  }
  return (
    <>
      <Heading size="md">
        Congrats! You&apos;re now a Knowledge Expert 🎉
      </Heading>
    </>
  );
}
