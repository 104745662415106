import { Box, Button, Flex, Heading } from '@chakra-ui/react';
import {
  TesterExtractAddEndpoint,
  TesterExtractListEndpoint,
  TesterExtractRunEndpoint,
} from '@core/types/types.endpoint.tester';

import { useApi } from 'src/shared/hooks/shared.hook.useApi';
import { useSubmitter } from 'src/shared/hooks/useSubmitter';
import { callApi } from 'src/shared/util/util.callApi';
import { TesterExtractItem } from 'src/Tester/Extract/TesterExtractItem';

export function TesterExtractPage() {
  const { data, refetch, loading, setData } = useApi<TesterExtractListEndpoint>(
    '/tester/extract/list',
    {},
  );

  const runAll = useSubmitter(async () => {
    if (!data) return;

    await callApi<TesterExtractRunEndpoint>('/tester/extract/run', {
      ts: data.test_cases.map((d) => d.ts),
    });
  });

  const adder = useSubmitter(async () => {
    await callApi<TesterExtractAddEndpoint>('/tester/extract/add', {});
    refetch();
  });

  return (
    <Box w="100%" margin="30px auto" maxW="1920px" p={2}>
      <Flex>
        <Heading
          size="md"
          display="flex"
          alignItems="center"
          w="40%"
          p={3}
          pl="60px"
          flex="none"
          maxW="720px"
        >
          Chat history
          <Button
            ml={4}
            colorScheme="messenger"
            size="sm"
            onClick={adder.submit}
            isLoading={loading || adder.isSubmitting}
          >
            Add
          </Button>
          <Button
            ml={4}
            colorScheme="messenger"
            size="sm"
            onClick={runAll.submit}
          >
            Run all
          </Button>
        </Heading>
        <Heading size="md" w="100%" p={3}>
          Status quo
        </Heading>
        <Heading size="md" w="100%" p={3}>
          Results
        </Heading>
      </Flex>

      {data?.test_cases

        .map((d, i) => (
          <TesterExtractItem
            key={d.ts}
            item={d}
            onSave={(item) => {
              data.test_cases[i] = item;
              setData({ ...data });
            }}
          />
        ))
        .reverse()}
    </Box>
  );
}
