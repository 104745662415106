import { useState } from 'react';

import { Box, Button, Center, Heading, Text } from '@chakra-ui/react';
import { DirectoryExpertsEndpoint } from '@core/types/types.endpoint.directory';

import { Logo } from 'src/shared/components/Logo';
import { UserField } from 'src/shared/components/UserField/UserField';
import { useDirectoryMe } from 'src/shared/hooks/useDirectory';
import { useSubmitter } from 'src/shared/hooks/useSubmitter';
import { callApi } from 'src/shared/util/util.callApi';

export function AppAddExperts() {
  const me = useDirectoryMe();

  const [selectedIds, setSelectedIds] = useState<string[]>([me.id]);
  const submitter = useSubmitter(async () => {
    const [, res] = await callApi<DirectoryExpertsEndpoint>(
      '/directory/experts',
      {
        expertIds: selectedIds,
      },
    );

    if (res) {
      // reload page
      window.location.reload();
    }
  });

  return (
    <Center minH="100vh" display="flex" flexDirection="column" p={3}>
      <Logo />
      <Heading size="sm" mt={10}>
        Next step:
      </Heading>
      <Text mt={3} color="gray.600">
        Who is responsible for keeping knowledge up-to-date?
      </Text>
      <Box mt={3} maxW="container.sm">
        <UserField
          placeholder="Select users"
          excludeDeleted
          selectedIds={selectedIds}
          setSelectedIds={setSelectedIds}
        />
      </Box>

      <Button
        mt={6}
        colorScheme="action"
        isDisabled={!selectedIds.length}
        onClick={submitter.submit}
        isLoading={submitter.isSubmitting}
      >
        Next
      </Button>
    </Center>
  );
}
