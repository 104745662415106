import { useState } from 'react';
import ReactTextareaAutosize from 'react-textarea-autosize';

import {
  Box,
  Button,
  Flex,
  Heading,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  Textarea,
} from '@chakra-ui/react';
import { DBExtractTestCase } from '@core/types/types.db';
import { TesterExtractSaveEndpoint } from '@core/types/types.endpoint.tester';
import { classifyTitle } from '@core/util/util.classifyTitle';

import { useSubmitter } from 'src/shared/hooks/useSubmitter';
import { callApi } from 'src/shared/util/util.callApi';

export function TesterExtractItem({
  item,
  onSave,
}: {
  item: DBExtractTestCase;
  onSave: (item: DBExtractTestCase) => void;
}) {
  const [value, setValue] = useState(item.input_text);

  const saveSubmitter = useSubmitter(
    async (classify?: 'none' | 'knowhow' | 'question') => {
      const [, res] = await callApi<TesterExtractSaveEndpoint>(
        '/tester/extract/save',
        {
          ts: item.ts,
          input_text: value,
          classify: classify ?? item.classify,
        },
      );

      if (res) {
        onSave(res);
      }
    },
  );

  const type = item.classify;

  return (
    <Flex>
      <Flex w="40%" flex="none" maxW="720px" p={3}>
        <Menu>
          <MenuButton
            as={Button}
            isDisabled={saveSubmitter.isSubmitting}
            variant="ghost"
            size="sm"
            px={0}
            mr={2}
          >
            {classifyTitle(type).split(' ')[0]}
          </MenuButton>
          <MenuList>
            <MenuItem value="none" onClick={() => saveSubmitter.submit('none')}>
              {classifyTitle('none')}
            </MenuItem>
            <MenuItem
              value="knowhow"
              onClick={() => saveSubmitter.submit('knowhow')}
            >
              {classifyTitle('knowhow')}
            </MenuItem>
            <MenuItem
              value="question"
              onClick={() => saveSubmitter.submit('question')}
            >
              {classifyTitle('question')}
            </MenuItem>
          </MenuList>
        </Menu>

        <Textarea
          as={ReactTextareaAutosize}
          value={value}
          onChange={(e) => setValue(e.target.value)}
          onBlur={() => {
            if (value !== item.input_text) {
              saveSubmitter.submit();
            }
          }}
          isDisabled={saveSubmitter.isSubmitting}
          maxRows={20}
          placeholder={`Message
----
Thread`}
        />
      </Flex>

      <Flex w="100%" justifyContent={'space-between'} alignContent={'center'}>
        <Box w="50%" p={3}>
          {classifyTitle(item.result.status_quo_classify)}
          {item.result.status_quo_qna.map((qna, index) => (
            <Box key={index} mt={3}>
              <Heading size="xs">{qna.question}</Heading>
              <Text size="xs">{qna.answer}</Text>
            </Box>
          ))}
        </Box>
        <Box w="50%" p={3}>
          {classifyTitle(item.result.experiment_classify)}
          {item.result.experiment_qna.map((qna, index) => (
            <Box key={index} mt={3}>
              <Heading size="xs">{qna.question}</Heading>
              <Text size="xs">{qna.answer}</Text>
            </Box>
          ))}
        </Box>
      </Flex>
    </Flex>
  );
}
