import { useState } from 'react';
import { BsFillLightningChargeFill } from 'react-icons/bs';
import { FaHashtag, FaLock, FaSlack } from 'react-icons/fa';

import { Button, Heading, Icon, Image, Text } from '@chakra-ui/react';
import {
  IntegrationAuthEndpoint,
  IntegrationUsageEndpoint,
} from '@core/types/types.endpoint.integration';
import {
  CarbonConnect,
  EmbeddingGenerators,
  IntegrationName,
} from 'carbon-connect';

import { useApi } from 'src/shared/hooks/shared.hook.useApi';
import { useDirectoryChannels } from 'src/shared/hooks/useDirectory';
import { callApi } from 'src/shared/util/util.callApi';

export function SettingsPage() {
  const channels = useDirectoryChannels({
    excludeDeleted: true,
    requireQbInChannel: true,
  });
  const { data, error, loading } = useApi<IntegrationUsageEndpoint>(
    '/integration/usage',
    {},
  );

  const [open, setOpen] = useState(false);
  const tokenFetcher = async () => {
    const [err, res] = await callApi<IntegrationAuthEndpoint>(
      '/integration/auth',
      {},
    );

    if (err || !res) {
      throw new Error('Failed to fetch token');
    }

    return res;
  };

  let label = '';
  if (loading) {
    label = 'Loading...';
  } else if (!error && !data?.connections.length) {
    label = 'No integrations connected yet';
  }

  return (
    <>
      <Heading size="md" mt={6}>
        Search Integrations
      </Heading>

      <Text mt={2}>Connected accounts: {label}</Text>

      {!!data?.connections.length &&
        data?.connections.map(({ icon, service }, i) => (
          <Text mt={2} key={i} display="flex" alignItems="center">
            <Image src={icon} w={6} h={6} mr={2} />
            {service}
          </Text>
        ))}

      <Button
        leftIcon={<Icon as={BsFillLightningChargeFill} />}
        colorScheme="action"
        mt={3}
        size="sm"
        onClick={() => {
          setOpen(true);
        }}
      >
        Manage Integrations
      </Button>
      <Text mt={2} fontWeight={500} color="gray.500" fontSize="xs">
        Include search results from your favorite tools{' '}
        <Button
          as="a"
          variant="link"
          colorScheme="blue"
          fontSize="xs"
          cursor="pointer"
          target="_blank"
          fontWeight={700}
          href="https://www.questionbase.com/docs/how-to-integrate-question-base-with-your-favorite-tools"
        >
          Learn more
        </Button>
      </Text>

      <CarbonConnect
        orgName="Question Base"
        brandIcon="/assets/logoimage.png"
        embeddingModel={EmbeddingGenerators.COHERE_MULTILINGUAL_V3}
        tokenFetcher={tokenFetcher}
        setOpen={setOpen}
        open={open}
        environment="PRODUCTION"
        enabledIntegrations={[
          {
            id: IntegrationName.NOTION,
            syncFilesOnConnection: true,
            enableAutoSync: true,
          },
          {
            id: IntegrationName.ZENDESK,
            syncFilesOnConnection: false,
            enableAutoSync: true,
            useCarbonFilePicker: true,
          },
          {
            id: IntegrationName.FRESHDESK,
            syncFilesOnConnection: false,
            enableAutoSync: false,
            useCarbonFilePicker: true,
          },
          {
            id: IntegrationName.INTERCOM,
            syncFilesOnConnection: false,
            enableAutoSync: false,
            useCarbonFilePicker: true,
          },
          {
            id: IntegrationName.GURU,
            syncFilesOnConnection: false,
            enableAutoSync: false,
            useCarbonFilePicker: true,
          },
          {
            id: IntegrationName.CONFLUENCE,
            syncFilesOnConnection: false,
            enableAutoSync: false,
            useCarbonFilePicker: true,
          },
          {
            id: IntegrationName.GOOGLE_DRIVE,
            syncFilesOnConnection: false,
            enableAutoSync: false,
            useCarbonFilePicker: true,
          },
          {
            id: IntegrationName.DROPBOX,
            syncFilesOnConnection: false,
            enableAutoSync: false,
            useCarbonFilePicker: true,
          },
          {
            id: IntegrationName.BOX,
            syncFilesOnConnection: false,
            enableAutoSync: false,
            useCarbonFilePicker: true,
          },
          {
            id: IntegrationName.SALESFORCE,
            syncFilesOnConnection: false,
            enableAutoSync: false,
            useCarbonFilePicker: true,
          },
          {
            id: IntegrationName.GITHUB,
            syncFilesOnConnection: false,
            enableAutoSync: false,
            useCarbonFilePicker: true,
          },
        ]}
        chunkSize={1500}
        overlapSize={20}
        entryPoint="INTEGRATION_LIST"
      ></CarbonConnect>
      <Heading size="md" mt={16}>
        Slack Channels
      </Heading>
      {channels.map((channel) => (
        <Text key={channel.id} display="flex" alignItems="center" mt={2}>
          <Icon as={channel.is_private ? FaLock : FaHashtag} mr={2} />{' '}
          {channel.name}
        </Text>
      ))}
      <Button
        leftIcon={<Icon as={FaSlack} />}
        colorScheme="action"
        as="a"
        href="https://www.questionbase.com/docs/invite-to-channels"
        mt={4}
        size="sm"
        target="_blank"
        rel="noopener noreferrer"
      >
        Add to channels
      </Button>
      <Text mt={2} fontWeight={500} color="gray.500" fontSize="xs">
        Answer & capture automatically from Slack{' '}
        <Button
          as="a"
          variant="link"
          colorScheme="blue"
          fontSize="xs"
          cursor="pointer"
          target="_blank"
          fontWeight={700}
          href="https://www.questionbase.com/docs/invite-to-channels"
        >
          Learn more
        </Button>
      </Text>
    </>
  );
}
